import {FC} from 'react';
import styled from 'styled-components';

import {Sizes} from '@src/components/theme_base';
import {HomeAwsSection} from '@src/views/home_aws_section';
import {HomeBottomLinksSection} from '@src/views/home_bottom_links_section';
import {HomeContactUsSection} from '@src/views/home_contact_us_section';
import {HomeHeroSection} from '@src/views/home_hero_section';
import {HomeOurServicesSection} from '@src/views/home_our_services_section';

export const Home: FC = () => (
  <Wrapper>
    <HomeHeroSection />
    <HomeOurServicesSection />
    <HomeAwsSection />
    <HomeContactUsSection />
    <HomeBottomLinksSection />
  </Wrapper>
);

Home.displayName = 'Home';

const Wrapper = styled.div`
  position: relative;
  width: ${Sizes.Content}px;
  margin: 0 auto;
  padding: 0 ${Sizes.ContentPadding}px;
  z-index: 1;
  overflow-x: hidden;
`;
