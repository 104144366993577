import {FC} from 'react';
import styled from 'styled-components';

import {createButton} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

import {HomePageColors, Sizes} from '@src/components/theme_base';

interface HomeFooterSectionProps {}

export const HomeFooterSection: FC<HomeFooterSectionProps> = () => {
  return (
    <Wrapper>
      <Block $justifyContent="flex-start">{`©${new Date().getFullYear()} OneScale. All rights reserved.`}</Block>
      <Block $justifyContent="center" $gap={24}>
        <Link>Privacy Policy</Link>
        <Sep />
        <Link>Terms of Use</Link>
      </Block>
      <Block $justifyContent="flex-end" $gap={12}>
        <Link>
          <SvgIcon name="Facebook" size={30} color="#ffffff" colorHover={HomePageColors.Pink} />
        </Link>
        <Link>
          <SvgIcon name="LinkedIn" size={30} color="#ffffff" colorHover={HomePageColors.Pink} />
        </Link>
      </Block>
    </Wrapper>
  );
};

HomeFooterSection.displayName = 'HomeFooterSection';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: ${Sizes.Content}px;
  height: 90px;
  margin: auto;
  padding: 0 ${Sizes.ContentPadding}px;
  background-color: ${HomePageColors.DarkDarkBlue};
  font-size: 22px;
`;

const Block = styled.div<{$justifyContent: 'flex-start' | 'flex-end' | 'center'; $gap?: number}>`
  width: 0;
  flex-grow: 1;
  flex-basis: 1;
  display: flex;
  align-items: center;
  justify-content: ${p => p.$justifyContent};
  ${p => optionalPx('gap', p.$gap)}
`;

const Link = createButton({
  type: 'a',
  theme: 'link',
  themeOverrides: {
    textColorActive: '#ffffff',
    textColorDisabled: '#ffffff',
    textColorHover: HomePageColors.Pink,
    textColorLoading: '#ffffff',
  },
});

const Sep = styled.div`
  width: 1px;
  height: 30px;
  background-color: #ffffff66;
`;
