import {FC, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {SvgIcon, SvgIconName} from '@shared-frontend/components/core/svg_icon';
import {useSession} from '@shared-frontend/lib/session_store';

import {FilledNavLinkPink} from '@src/components/theme';
import {Colors, FontWeight, Sizes} from '@src/components/theme_base';

export interface Page {
  name: string;
  path: string;
  icon: SvgIconName;
}
export const PAGES: Record<string, Page> = {
  datalake: {path: '/demo/home', icon: 'Home', name: 'DATA LAKE'},
  graphs: {path: '/demo/graphs', icon: 'Graph', name: 'GRAPHS'},
  users: {path: '/demo/users', icon: 'Users', name: 'UTILISATEURS'},
  costs: {path: '/demo/costs', icon: 'Dollar', name: 'COÛTS'},
  apidoc: {path: '/demo/restapi', icon: 'RestApi', name: 'API DOC'},
  logout: {path: '/logout', icon: 'Logout', name: 'LOGOUT'},
};
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const defaultPage = PAGES.datalake!;
export type PageName = keyof typeof PAGES;

export const Navigation: FC = () => {
  const {
    main: {logo},
  } = useTheme();
  const location = useLocation();
  const session = useSession();

  const currentPage = useMemo(
    () => Object.values(PAGES).find(p => p.path === location.pathname),
    [location.pathname]
  );

  const isInDemo = location.pathname.startsWith('/demo');
  const isHome = location.pathname === '/';

  return (
    <Wrapper>
      <Container $connected={session !== undefined}>
        <UnthemedNavLink to="/" aria-label="OneScale">
          {logo}
        </UnthemedNavLink>
        <RightMenu hidden={!isHome || session !== undefined}>
          <FilledNavLinkPink
            to="#contact"
            overrides={{paddingTop: 22, paddingBottom: 22, fontSize: 20}}
          >
            GET IN TOUCH
            <SvgIcon name="ArrowRight" color="#ffffff" width={25} />
          </FilledNavLinkPink>
        </RightMenu>
        <RightMenu hidden={!isInDemo && session === undefined}>
          {Object.values(PAGES).map(page => {
            const isSelected = currentPage === page;
            return (
              <MenuItem key={page.path} to={page.path} $selected={isSelected}>
                <SvgIcon
                  name={page.icon}
                  size={18}
                  color={isSelected ? Colors.White : Colors.Gray}
                />
                <MenuTitle>{page.name}</MenuTitle>
              </MenuItem>
            );
          })}
        </RightMenu>
      </Container>
    </Wrapper>
  );
};
Navigation.displayName = 'Navigation';

const Container = styled.div<{$connected: boolean}>`
  display: flex;
  justify-content: space-between;
  width: ${p => (p.$connected ? '100%' : `${Sizes.Content}px`)};
  padding: 0 ${Sizes.ContentPadding}px;
`;

const Wrapper = styled.div`
  height: 174px;
  z-index: 999;
  text-align: center;
  padding: 10px;
  color: white;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: ${Sizes.Content}px;
  font-weight: ${FontWeight.SemiLight};
`;

const Menu = styled.nav`
  user-select: none;
  margin-top: 0px;
  font-size: 16px;
`;

const RightMenu = styled(Menu)`
  float: right;
  user-select: none;
`;

const MenuItem = styled(UnthemedNavLink)<{$selected?: boolean}>`
  display: inline-block;
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;
  text-align: left;
  cursor: pointer;
  padding: 10px 20px;
  color: ${({$selected}) => ($selected ? Colors.White : Colors.Gray)};
`;

const MenuTitle = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
`;
